import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import LogoIweek from "../../static/icons/logo_iweek.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    position: "relative",
    justifyContent: "space-between",
    padding: "30px 5%",
  },
  c: {
    width: "fit-content",
    color: "#161832",
    fontFamily: "Poppins Regular",
    fontWeight: 400,
  },
  logo: {
    transform: "translate(-50%, -50%)",
    width: 150,
    height: 200,
    top: "0%",
    left: "50%",
    position: "absolute",
  },
  applyBtn: {
    padding: "4px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 23,
    border: "1px solid  #161832",
    textDecoration: "none",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
  },

  "@media (max-width: 1020px)": {
    root: {
      padding: "20px 5%",
    },
    c: {
      fontSize: 14,
    },
    logo: {
      width: 115,
      height: 160,
    },
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root} id="footer">
      <Typography className={classes.c} variant="span">
        © iWeek 2024
      </Typography>
      <img src={LogoIweek} alt="logo iweek" className={classes.logo} />
      <Button rel="noopener noreferrer"  target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSepKxxlTlZl7v9hObkiR6WIcBjEOiphYNThw-JV-x5YYnzRvA/viewform?usp=sf_link" className={classes.applyBtn}>Apply</Button>
    </footer>
  );
};

export default Footer;
