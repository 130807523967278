import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import LogoIweek from "../../static/icons/logo_iweek.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 70,
    padding: "0 10%",
  },
  logo: {
    width: 48,
    height: 48,
    cursor: "pointer",
  },
  nav: {
    fontFamily: "Poppins Regular",
  },
  ulNav: {
    padding: 0,
    margin: 0,
    display: "flex",
    gap: 20,
    listStyle: "none",
    "& a": {
      textDecoration: "none",
      color: "#000",
    },
    "& li a": {
      color: "#161832",
      fontWeight: 700,
      "&:hover": {
        color: "#000",
      },
    },
  },
  applyBtn: {
    padding: "4px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 23,
    border: "1px solid  #161832",
    textDecoration: "none",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
  },
});

const Home = () => {
  const classes = useStyles();

  const goToHome = () => {
    window.location.href = "/";
  }

  // const links = [
  //   {
  //     title: "Program",
  //     link: "/program",
  //   },
  //   {
  //     title: "FAQ",
  //     link: "/faq",
  //   },
  //   {
  //     title: "Contact us",
  //     link: "/contact",
  //   },
  // ];

  return (
    <header className={classes.root} id="header">
      <img src={LogoIweek} alt="logo iweek" className={classes.logo} onClick={()=>goToHome()}/>
      {/* <nav className={classes.nav}>
        <ul className={classes.ulNav}>
          {links.map((link) => (
            <li>
              <a href={link.link}>{link.title}</a>
            </li>
          ))}
        </ul>
      </nav> */}
      <Button rel="noopener noreferrer"  target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSepKxxlTlZl7v9hObkiR6WIcBjEOiphYNThw-JV-x5YYnzRvA/viewform?usp=sf_link" className={classes.applyBtn}>Apply</Button>
    </header>
  );
};

export default Home;
